<template>
  <load-component :load="confirm" />
</template>

<script>
import LoadComponent from '@/components/loadComponent/LoadComponent';
import auth from '@/api/auth';

export default {
  name: 'ConfirmRegistration',
  components: { LoadComponent },
  methods: {
    async confirm() {
      const {
        query: { token },
      } = this.$route;
      try {
        if (!token) return this.$router.push({ name: 'login' });
        await auth.confirmRegistration(token);
        return this.$router.push({ name: 'login' });
      } catch (e) {
        return Promise.reject();
      }
    },
  },
};
</script>
