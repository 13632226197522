<template>
  <div v-if="state.isLoading" ref="loadComponent">
    <slot name="loading">
      <div class="bg-grey-light d-flex loading min-vh-100">
        <div class="m-auto t-center">
          <span class="spinner mx-auto mb-2"></span>
          <h2>{{ $t('Loading') }}...</h2>
        </div>
      </div>
    </slot>
  </div>
  <div v-else-if="state.isSuccess">
    <slot name="success" />
  </div>
  <div v-else-if="state.isError">
    <slot name="error" />
  </div>
</template>

<script>
import State from '@/helpers/State';

export default {
  props: {
    load: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      state: new State(['loading', 'success', 'error']),
    };
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      this.state.setLoading();

      try {
        await this.load();
        this.state.setSuccess();
      } catch (e) {
        this.state.setError();
      }
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>
