/* eslint-disable class-methods-use-this */
class State {
  constructor(states, initial = null) {
    this.states = [];
    this.checkerPrefix = 'is';
    this.setterPrefix = 'set';
    this.init(states);
    this.default = this.normalizeState(initial || this.states[0]);
    this.current = this.normalizeState(initial || this.states[0]);
  }

  init(states) {
    if (!(states instanceof Array)) {
      throw Error('States shoud be instance of Array.');
    }

    if (states.length < 1) {
      throw Error('It should be at least one state');
    }

    states.forEach((state) => {
      this.addState({
        name: state,
        readonly: false,
      });
    });
  }

  addState({ name, getter, setter, checker, readonly = true }) {
    this.states.push(this.normalizeState(name));

    this.defineGetter(name, getter);

    if (readonly === false) {
      this.defineSetter(name, setter);
    }

    this.defineChecker(name, checker);
  }

  normalizeState(state) {
    if (typeof state !== 'string') {
      throw Error('State should be type of String.');
    }

    return state.toLowerCase();
  }

  defineGetter(state, handler = null) {
    this.setHandler(state, handler || this.getter(state));
  }

  defineSetter(state, handler = null) {
    const name = `set${this.ucfirst(state)}`;

    this.setHandler(name, handler || this.setter(state));
  }

  defineChecker(state, handler = null) {
    const name = this.checkerPrefix + this.ucfirst(state);

    this.setProperty(name, handler || this.checker(state));
  }

  setHandler(name, handler) {
    this[name] = handler;
  }

  setProperty(name, property) {
    Object.defineProperty(this, name, {
      get: property,
    });
  }

  getter(state) {
    return () => state;
  }

  setter(state) {
    return () => {
      this.current = state;
    };
  }

  checker(state) {
    return () => this.current === state;
  }

  isState(state) {
    return this.current === state;
  }

  setState(state) {
    this.current = state;
  }

  setDefault(state) {
    this.default = state;
  }

  setCheckerPrefix(prefix) {
    this.checkerPrefix = prefix;
  }

  setSetterPrefix(prefix) {
    this.setterPrefix = prefix;
  }

  reset() {
    this.setState(this.default);
  }

  ucfirst(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}

export default State;
